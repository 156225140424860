import { configureApi } from "react-reqq";
import { toastError } from "modules/common/toast";
import _ from "lodash";
import { createBrowserHistory } from "history";
import { getFirstMessage } from "modules/common/helper";

export const history = createBrowserHistory();
export const rootApi = process.env.REACT_APP_END_POINT;
export const store = configureApi({
  endpoint: rootApi,
  requestHeaders: () => {
    const token = sessionStorage.getItem("token");
    if (!token) return {};
    return {
      Authorization: `Bearer ${token}`,
    };
  },
  timeout: 180000,
  onError: (err) => {
    try {
      const dispatchError = {
        401: () => {
          const message = _.get(err, "response.errors.0.detail");
          if (message === "Token has expired or invalid.") {
            toastError("Session has expired or invalid.");
            history.push("/logout");
            return;
          }
          if (_.get(err, "response.message", "").indexOf("Bad token") > -1) {
            toastError("Unauthorized Account.");
            history.push("/logout");
            return;
          }
          const { errors = [] } = _.get(err, "response");
          toastError(getFirstMessage(errors));
        },
        422: () => {
          const { errors = [] } = _.get(err, "response");
          toastError(getFirstMessage(errors), { timeout: "50000" });
        },
        500: () => toastError("Request Error"),
      };

      return dispatchError[err.status] && dispatchError[err.status]();
    } catch (error) {
      return null;
    }
  },
});

import React from "react";
import lgLogo from "assets/images/logo-with-title.svg";

function Page404Container() {
  return (
    <div className="main-form">
      <div className="min-h-screen lg:min-h-3xl text-primary-400 flex flex-row justify-center items-center">
        <div>
          <div className="w-auto flex items-center justify-center mb-3">
            <img
              src={lgLogo}
              alt=""
              className="w-full h-24 flex justify-center"
            />
          </div>
          <h3 className="mb-0 text-xl lg:text-3xl font-bold tracking-wide text-center ">
            Error: Reference number not found.
          </h3>
        </div>
      </div>
    </div>
  );
}
Page404Container.defaultProps = {};

Page404Container.propTypes = {};

export default Page404Container;

import React from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import cn from "classnames";
import TitleContainer from "../components/TitleContainer";
import { renderDisclaimer } from "../components/RenderDisclaimer";
// import MapForm from "../form/MapForm";
import DetailsForm from "../form/DetailsForm";
import { useGetSubsciberByRefNumber } from "../hooks";

function FormContainer() {
  const { reference_number } = useParams();
  const [
    isApiLoading,
    error,
    subscriber,
    disclaimers,
  ] = useGetSubsciberByRefNumber(reference_number);

  const history = useHistory();

  React.useEffect(() => {
    if (!reference_number) {
      history.push("/error-page");
    }
  }, [history, reference_number]);

  // const vw = Math.max(
  //   document.documentElement.clientWidth || 0,
  //   window.innerWidth || 0
  // );

  // const renderMap = React.useMemo(() => {
  //   if (vw > 768) {
  //     return <MapForm />;
  //   }
  //   return null;
  // }, [vw]);

  if (isApiLoading && isEmpty(subscriber)) {
    return (
      <div className="main-form">
        <div className="min-h-screen lg:min-h-3xl text-primary-400 flex flex-row justify-center items-center">
          <h3 className="mb-0 text-xl lg:text-3xl font-bold tracking-wide text-center ">
            Loading please wait...
          </h3>
        </div>
      </div>
    );
  }

  if (error) {
    return <Redirect to="/error-page" />;
  }

  const { logo, with_map } =
    subscriber?.included?.subscriber?.attributes ?? false;
  const receiver_detail =
    subscriber?.included?.receiver_detail?.attributes ?? {};

  return (
    <div
      className={cn("grid main-form", {
        "grid-cols-1": !with_map,
      })}
    >
      <div className="p-8 lg:col-span-4 2xl:col-span-1">
        <TitleContainer
          reference_number={reference_number}
          subsciber_logo={logo}
        />
        {renderDisclaimer.header(disclaimers?.header)}
        <div className="mt-5">
          <DetailsForm
            with_map={!!with_map}
            receiver_detail={receiver_detail}
          />
        </div>
      </div>
      {
        //   with_map ? (
        //   <div className="lg:col-span-3 2xl:col-span-1">{renderMap}</div>
        // ) : (
        //   <div />
        // )
      }
    </div>
  );
}
FormContainer.defaultProps = {};

FormContainer.propTypes = {};

export default FormContainer;

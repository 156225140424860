import { req } from "react-reqq";
import _ from "lodash";
import { set } from "session";
import { toastWarning } from "modules/common/toast";
import { flatIncludedToItem } from "modules/common/helper";
import * as c from "./constants";

export const generatePaymentReference = (
  reference_number,
  payload,
  callback = () => {}
) => {
  req.put({
    key: c.GENERATE_PAYMENT_REFERENCE,
    url: `/api/v1/client/transactions/${reference_number}`,
    payload,
    onSuccess: (res) => {
      const payment_gateway_url = _.get(
        res,
        "response.data.attributes.payment_gateway_url",
        ""
      );
      set("payment_gateway_url", payment_gateway_url);
      callback(payment_gateway_url);
    },
    onError: () => {
      toastWarning("Generate reference number is expired or invalid.");
    },
  });
};

export const getSubscriberByRefNumber = (reference_number, errCallback) => {
  req.get({
    key: c.GET_SUBSCRIBER_BY_REF_NUMBER,
    url: `/api/v1/client/transactions/${reference_number}`,
    transform: (res) => {
      const { data, included } = res;
      const x = flatIncludedToItem(data, included);
      return x;
    },
    onError: () => {
      errCallback();
    },
  });
};

export const getSubscriberContacts = (subscriber_id) => {
  req.get({
    key: c.GET_SUBSCRIBER_CONTACTS,
    url: `/api/v1/subscribers/${subscriber_id}/contacts`,
    transform: ({ data }) => {
      const x = data.map((item) => ({
        description: item?.attributes?.description,
        provider: item?.attributes?.provider,
        id: item?.id,
      }));
      const newData = {
        email:
          x
            ?.filter(
              (item) => item?.provider === "Email" || item?.provider === "email"
            )
            .map((item) => item?.description)
            .join() || "",
        contact_number: x?.filter(
          (item) => item?.provider !== "Email" && item?.provider !== "email"
        ),
      };
      return newData;
    },
  });
};

export const getSubscriberDisclaimers = (subscriber_id) => {
  req.get({
    key: c.GET_SUBSCRIBER_DISCLAIMERS,
    url: `/api/v1/subscribers/${subscriber_id}/disclaimers`,
    transform: ({ data }) => {
      const x = data.map((item) => ({
        description: item?.attributes?.description,
        title: item?.attributes?.title,
        id: item?.id,
        type: item?.attributes?.type,
      }));
      const newData = {
        footer: x?.filter((item) => item?.type === "footer"),
        header: x?.filter((item) => item?.type === "header"),
        rate: x?.filter((item) => item?.type === "rate"),
      };
      return newData;
    },
  });
};

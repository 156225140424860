import React from "react";

export const renderDisclaimer = {
  header: (value) => {
    return (
      <div className="text-red-500 tracking-wide">
        {value?.map((item) => (
          <React.Fragment key={item?.id}>
            <h4 className="text-sm font-medium my-3">{item?.title} </h4>
            <p className="text-xs">{item?.description}</p>
          </React.Fragment>
        ))}
        {/* <h4 className="text-sm font-medium my-3">DISCLAIMER NOTICE: </h4>
        <p className="text-xs">
          IF DECIDED TO PROCEED TO PAY FOR THE DELIVERY SERVICE FEE AND PAYMENT
          HAS BEEN SUCCESSFUL, ALL TRANSACTIONS ARE NON-REFUNDABLE (KUNG
          NAKAPAG-PASYA NA BAYARAN ANG PAGHATID NG INYONG NBI CLEARANCE AT ANG
          IYONG KABAYARAN AY LUMITAW NA MATAGUMPAY, ITO AY HINDI NA MAIBABALIK).
        </p>
        <h4 className="text-sm font-medium my-3">
          REMINDER (MUNTING PAALALA):{" "}
        </h4>
        <p className="text-xs">
          THE NBI REFERENCE NUMBER PAYMENT IS DIFFERENT FROM THE DELIVERY FEE
          PAYMENT (MAGKAHIWALAY ANG BAYAD NG NBI CLEARANCE FEE SA DELIVERY FEE).
        </p> */}
      </div>
    );
  },
  footer: (value) => {
    return (
      <div className="mb-4 space-y-2">
        {value?.map((item) => (
          <p className="text-red-500 text-xs font-medium" key={item?.id}>
            {item?.description}
          </p>
        ))}
      </div>
    );
  },
  map: (
    <div className="mb-4 text-red-500 tracking-wide">
      <h4 className="text-sm font-medium my-2">DISCLAIMER NOTICE: </h4>
      <p className="text-xs font-medium">
        For us to give you the best service, please pin your preferred exact
        location of the delivery. (Optional)
      </p>
    </div>
  ),
  ncr_modal: (
    <div className="mb-4 tracking-wide">
      <h4 className="text-3xl font-bold tracking-wide my-2 text-center mb-2 text-red-500">
        DISCLAIMER NOTICE:{" "}
      </h4>
      <p className="text-xl font-medium text-gray-600 mb-4">
        This delivery service is currently available within{" "}
        <span className="font-bold">NCR</span> only. Outside{" "}
        <span className="font-bold">NCR</span> services will be available soon.
      </p>
      <p className="text-xl font-medium text-gray-600">
        If you choose outside <span className="font-bold">NCR</span>, this
        session will automatically end.
      </p>
    </div>
  ),
  rate: (value) => {
    return (
      <div className="mb-4 tracking-wide">
        <h4 className="text-3xl font-bold tracking-wide my-2 text-center mb-6 text-green-500">
          Thank you for your visit!
        </h4>
        <div className="text-xl font-medium text-gray-600 space-y-4">
          <p>
            Our delivery service is now available in NCR and beyond.
            Corresponding delivery fee as follows:
          </p>
          <div className="flex flex-col">
            {value?.map((item) => (
              <span className="font-bold text-gray-700" key={item?.id}>
                {item?.title} - {item?.description}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  },
  hotlines: (value) => {
    return (
      <>
        <p>
          If you have any questions or concerns, feel free to contact these
          mobile numbers below:
        </p>
        <ul className="tracking-wide space-y-1">
          {value?.map((item) => (
            <li key={item?.id}>
              <span className="uppercase">{item?.provider}</span>:{" "}
              <span className="text-gray-700 font-bold">
                {item?.description}
              </span>
            </li>
          ))}
        </ul>
      </>
    );
  },
  email: (value) => {
    return (
      <p>
        You can also email us thru:{" "}
        <a
          href="mailto:support@deliverybox.ph"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline hover:text-primary-500"
        >
          {value}
        </a>
      </p>
    );
  },
  limited_area: (
    <div className="text-red-500 tracking-wide text-sm space-y-2">
      <p>
        REMINDER: Delivery services are limited to serviceable areas of our
        courier.
      </p>
      <p>
        PAALAALA: Ang aming delivery services ay limitado lamang sa serviceable
        areas ng aming mga couriers.
      </p>
    </div>
  ),
};

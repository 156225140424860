import React from "react";
import lgLogo from "assets/images/logo-with-title.svg";

function CancelTransaction() {
  return (
    <div className="main-form">
      <div className="min-h-screen lg:min-h-3xl text-primary-400 flex flex-row justify-center items-center">
        <div>
          <div className="w-auto flex items-center justify-center mb-3">
            <img src={lgLogo} alt="" className="flex justify-center" />
          </div>
          <h3 className="mb-0 text-5xl font-bold tracking-wide">Thank you.</h3>
        </div>
      </div>
    </div>
  );
}
CancelTransaction.defaultProps = {};

CancelTransaction.propTypes = {};

export default CancelTransaction;

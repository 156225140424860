import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import { history, store } from "store";

export const closeModal = () => {
  const root = document.getElementById("modal-root");
  if (!root) {
    alert("Modal root not found!"); // eslint-disable-line
    return;
  }

  setTimeout(() => {
    document.body.className = "";
    render(<div id="modal-root" />, root);
  }, 200);
};

const renderAlignment = {
  top: "items-start",
  middle: "items-center",
  bottom: "items-end",
};

export const showModal = (options) => {
  const opt = {
    title: "",
    modalSize: "modal-md",
    titleClassName: "",
    align: renderAlignment[options?.align ?? "middle"],
    content: "Modal Body",
    noClose: false,
    titleContainer: true,
    isFocusTrap: true,
    onCloseCallback: false,
    ...options,
  };

  const root = document.getElementById("modal-root");

  let dialogRef;
  const setDialogRef = (ref) => {
    dialogRef = ref;
  };
  let backgroundRef;
  const setBackgroundRef = (ref) => {
    backgroundRef = ref;
  };

  // eslint-disable-next-line no-unused-vars
  const prevClassName = document.body.className;
  const prevElem = document.activeElement;
  document.body.className = "overflow-hidden fixed w-full";

  const onClose = () => {
    try {
      dialogRef.classList.add("exit");
      setBackgroundRef.classList.add("exit");
      if (opt.onCloseCallback) {
        opt.onCloseCallback();
      }
    } catch (err) {
      // do nothing...
    }
    setTimeout(() => {
      document.body.className = "";
      render(<div id="modal-root" />, root);
      setTimeout(() => {
        try {
          prevElem.blur();
        } catch (err) {} // eslint-disable-line
      }, 100);
    }, 200);
  };

  const modalContent = <div>{opt.content(onClose)}</div>;

  const escFunction = (e) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  document.addEventListener("keydown", escFunction, false);

  render(
    <Provider store={store}>
      <Router history={history}>
        <div
          ref={setDialogRef}
          className="fixed z-50 inset-0 overflow-y-auto modal px-6 md:px-0"
        >
          <div className={`flex justify-center min-h-screen ${opt.align}`}>
            <div
              className={`inline-block align-bottom bg-white rounded-lg shadow-xl sm:my-8 ${opt.modalSize}`}
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 py-5 sm:p-6 rounded-lg">
                <div className={`${opt.titleClassName}`}>
                  <div
                    className={`flex ${
                      opt.title ? "justify-between" : "justify-end"
                    }`}
                  >
                    {opt.title && (
                      <p className="text-xl text-gray-600 font-bold mb-2">
                        {opt?.title}
                      </p>
                    )}
                    {!opt.noClose && (
                      <div className="relative">
                        <button
                          type="button"
                          tabIndex="-1"
                          className="absolute -top-2 -right-2 rounded-full transition duration-300 east-in-out group border-2 border-gray-400 hover:border-primary-600 hover:bg-primary-600 outline-none"
                          onClick={onClose}
                        >
                          <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-6 h-6 transition duration-300 east-in-out text-gray-400 group-hover:text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {opt.isFocusTrap ? (
                  <FocusTrap
                    focusTrapOptions={{
                      allowOutsideClick: () => true,
                    }}
                  >
                    {modalContent}
                  </FocusTrap>
                ) : (
                  modalContent
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          ref={backgroundRef}
          className="fixed inset-0 transition-opacity z-40"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-black opacity-50" />
        </div>
      </Router>
    </Provider>,
    root
  );
};

export const ModalMarker = () => {
  return <div id="modal-root" />;
};

export const showAlert = (options) => {
  const root = document.getElementById("alert-root");
  if (!root) {
    alert("Alert root not found!"); // eslint-disable-line
    return;
  }

  const opt = {
    title: "",
    isLoadingKey: null,
    isLoadingMethod: null,
    isFocusTrap: false,
    modalSize: "modal-sm",
    titleClassName: "",
    align: "justify-center flex items-center",
    content: "Confirmation Message",
    onYes: (close) => {
      close();
    },
    onYesLabel: "Yes",
    onNo: (close) => {
      close();
    },
    onNoLabel: "No",
    ...options,
  };

  let dialogRef;
  const setDialogRef = (ref) => {
    dialogRef = ref;
  };
  let backgroundRef;
  const setBackgroundRef = (ref) => {
    backgroundRef = ref;
  };

  const prevClassName = document.body.className;
  const prevElem = document.activeElement;
  document.body.className = "overflow-hidden";

  const onClose = () => {
    try {
      dialogRef.classList.add("exit");
      setBackgroundRef.classList.add("exit");
      if (opt.onCloseCallback) {
        opt.onCloseCallback();
      }
    } catch (err) {
      // do nothing...
    }
    setTimeout(() => {
      document.body.className = prevClassName;
      render(<div id="alert-root" />, root);
      setTimeout(() => {
        try {
          prevElem.blur();
        } catch (err) {} // eslint-disable-line
      }, 100);
    }, 200);
  };

  const handleYes = () => {
    opt.onYes(onClose);
    setTimeout(() => {
      try {
        prevElem.blur();
      } catch (err) {} // eslint-disable-line
    }, 100);
  };
  const handleNo = () => {
    opt.onNo(onClose);
    setTimeout(() => {
      try {
        prevElem.blur();
      } catch (err) {} // eslint-disable-line
    }, 100);
  };

  const escFunction = (e) => {
    if (e.keyCode === 27) {
      onClose();
      prevElem.blur();
    }
  };

  document.addEventListener("keydown", escFunction, false);

  const renderContent = () => {
    if (typeof opt.content === "function") return opt.content(onClose);
    if (typeof opt.content === "string")
      return <div className="px-3">{opt.content}</div>;
    return "n/a";
  };

  render(
    <Provider store={store}>
      <Router history={history}>
        <div
          ref={setDialogRef}
          className="fixed z-50 inset-0 overflow-y-auto modal px-6 md:px-0"
        >
          <div className={`flex justify-center min-h-screen ${opt.align}`}>
            <div
              className="inline-block align-bottom bg-white rounded-lg shadow-xl sm:my-8"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 py-5 sm:p-6 rounded-lg">
                {opt.isFocusTrap ? (
                  <FocusTrap
                    focusTrapOptions={{
                      allowOutsideClick: () => true,
                    }}
                  >
                    {renderContent()}
                  </FocusTrap>
                ) : (
                  renderContent()
                )}
                <div className="flex justify-center space-x-2 mt-4">
                  {opt.onYesLabel && (
                    <button
                      className="btn primary px-8 w-full"
                      type="button"
                      onClick={handleYes}
                    >
                      {opt.onYesLabel}
                    </button>
                  )}
                  {opt.onNoLabel && (
                    <button
                      className="btn light px-8 w-full"
                      type="button"
                      onClick={handleNo}
                    >
                      {opt.onNoLabel}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={backgroundRef}
          className="fixed inset-0 transition-opacity z-40"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-black opacity-50" />
        </div>
      </Router>
    </Provider>,
    root
  );
};

export const AlertMarker = () => {
  return <div id="alert-root" />;
};

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const FloatingTextArea = React.forwardRef((props, ref) => {
  const {
    name,
    label,
    containerClassName,
    labelClassName,
    required,
    type,
    error,
    isLowerCase,
    ...rest
  } = props;

  const handleOnFocus = (e) => {
    e.preventDefault();
    document.getElementById(_.get(rest, "id") || name).focus();
  };

  return (
    <div className="flex flex-col w-full relative">
      <div
        className={containerClassName}
        onClick={handleOnFocus}
        role="presentation"
        ref={ref}
      >
        <textarea
          id={_.get(rest, "id") || name}
          type={type}
          rows="4"
          autoComplete="chrome-off"
          placeholder={label}
          className="floating-input textarea"
          {...rest}
        />
        <label className={labelClassName} htmlFor={_.get(rest, "id") || name}>
          {label} {required ? <span className="text-primary">*</span> : ""}
        </label>
      </div>
      {error && (
        <small className="flex text-xxs absolute bottom-2 right-0 px-2 bg-red-50 rounded text-red-500">
          {error?.message ?? ""}
        </small>
      )}
    </div>
  );
});

FloatingTextArea.defaultProps = {
  label: "Name",
  containerClassName: "floating-form",
  labelClassName: "floating-label",
  required: false,
  type: "text",
  error: false,
  isLowerCase: false,
};

FloatingTextArea.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  isLowerCase: PropTypes.bool,
};

export default React.memo(FloatingTextArea);

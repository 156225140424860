import React from "react";
import PropTypes from "prop-types";

function TOCModal({ onClose, onAgree }) {
  const handleOnAgree = (value) => (e) => {
    e.preventDefault();
    onClose();
    onAgree(value);
  };

  return (
    <>
      <h1 className="text-2xl font-bold mb-0 text-center">
        Terms and Condition & Data Privacy
      </h1>
      <div className="w-auto border-b border-primary-200 my-6" />
      <div>
        <div id="section-1" className="mb-12">
          <h3 className="text-lg font-semibold mb-6">1. DEFINITION OF TERMS</h3>
          <p>
            Capitalized terms used here in shall have the meanings set forth in
            the DPA, the IRR, and in Clause 1 of these Terms and Conditions.
          </p>
          <div className="pl-6">
            <p className="my-6">
              1. Consent of the Data Subject - refers to any freely given,
              specific, informed indication of will, whereby a Data Subject
              agrees to the collection and processing of his/her Personal Data.
              Consent shall be evidenced by written, electronic or recorded
              means. It may also be given on behalf of a Data Subject by an
              authorized representative or an agent specifically authorized by
              the Data Subject to do so;
            </p>
            <p className="my-6">
              2. Data Subject - refers to an individual whose personal,
              sensitive personal, or privileged information is processed by a
              Party and shared to the other Party;
            </p>
            <p className="my-6">
              3. Data Sharing - refers to the disclosure or transfer by one
              Party of the Personal Data of its Data Subject to another Party;
            </p>
            <p className="my-6">
              4. Discloser - refers to the Party to disclosing or sharing the
              Personal Data of its Data Subjects to the other Party;
            </p>
            <p className="my-6">
              5. PersonalData - refers to all types of Personal Information of a
              Party’s Data Subject;
            </p>
            <p className="my-6">
              6. Personal Information - refers to any information whether
              recorded in a material form or not, from which the identity of an
              individual is apparent or can be reasonable and directly
              ascertained by the entity holding the information, or when put
              together with other information would directly and certainly
              identify an individual;
            </p>
            <p className="my-6">
              7. Processing - refers to any operation or set of operations
              performed upon Personal Data including, but not limited to, the
              collection, recording, organization, storage, updating or
              modification, retrieval, consultation, use, consolidation,
              blocking, erasure or destruction of Personal Data. Processing may
              be performed through automated means, or manual processing, if the
              Personal Data is contained or is intended to be contained in a
              filing system.
            </p>
            <p className="my-6">
              8. Recipient - refers to the Party receiving the Personal Data
              from the other Party or to which the Discloser shared the Personal
              Data of its Data Subject; and
            </p>
            <p className="my-6">
              9. Security Breach - any unauthorized, unlawful or accidental
              access, processing, disclosure, alteration, loss, damage, or
              destruction of data transmitted, stored, or otherwise
              processed,whether by human or natural causes.
            </p>
            <div className="my-6">
              1.0 Profiling - refers to any form of automated processing of
              personal data consisting of the use of personal data to evaluate
              certain personal aspects relating to a natural person, in
              particular to analyze or predict aspects concerning that natural
              person’s performance at work, economic situation, health, personal
              preferences, interests, reliability, behavior, location or
              movements.
            </div>
          </div>
        </div>
        <div id="section-2" className="mb-12">
          <h3 className="text-lg font-semibold mb-6">
            2. JOIN/MUTUAL OBLIGATIONS OF THE PARTIES
          </h3>
          <p>
            The Parties shall faithfully comply with the Data Privacy Act of
            2012 and its IRR. In particular, they shall:
          </p>
          <div className="pl-6">
            <p className="my-6">
              1. Uphold the rights of each Data Subject, including the right to
              refuse, withdraw consent, or object;
            </p>
            <p className="my-6">
              2. Warrant and guarantee that they have both placed appropriate
              administrative, physical, technical and organizational security
              measures to protect Personal Data from Security Breach;
            </p>
            <p className="my-6">
              3. Ensure that their respective employees, personnel and agents
              observe the confidentiality of the Personal Data and will prohibit
              any unauthorized access, improper use, duplication, disclosure,
              destruction of any of the Personal Data in whole or in part;
            </p>
            <p className="my-6">
              4. Ensure that their respective employees, personnel and agents
              have received appropriate training in data protection prior to
              their access or processing of Personal Data and have signed a
              written undertaking that they understand and will act in
              accordance with their responsibilities for confidentiality under
              the Terms;
            </p>
            <p className="my-6">
              5. Notify each other of any unauthorized possession, use or
              disclosure of Personal Data by any unauthorized person or entity;
            </p>
            <p className="my-6">
              6. Shall not share the Personal Data of each other’s Data Subject
              to a third party without the written permission of the other
              Party; and
            </p>
            <p className="my-6">
              7. Shall not subcontract or engage a third party to process the
              Personal Data obtained from the other Party without the written
              consent of the Data Subject and of such other Party, and only
              after the subcontractor has provided all the necessary assurance
              and guarantees that it has adequate administrative, physical,
              technical, organizational and procedural security measures to
              protect the Personal Data.
            </p>
          </div>
        </div>
        <div id="section-3" className="mb-12">
          <h3 className="text-lg font-semibold mb-6">
            3. OBLIGATION OF THE DISCLOSER
          </h3>
          <p>The Discloser shall:</p>
          <div className="pl-6">
            <p className="my-6">
              1. Obtain the Data Subject’s consent prior to the sharing of the
              Data Subject’s Personal Data to, and further processing thereof
              by, the other Party, subject to exemptions under the Data Privacy
              Act of 2012, its Implementing Rules and Regulations (IRR) and
              other applicable laws and regulations;
            </p>
            <div className="my-6">
              <p>2. Must provide the Data Subject the following information:</p>
              <div className="pl-6">
                <p className="my-4">1.1 Identity of the Recipient;</p>
                <p className="my-4">1.2 Purpose/s of Data Sharing;</p>
                <p className="my-4">
                  1.3 Categories of Personal Data to be shared;
                </p>
                <p className="my-4">
                  1.4 Existence of the rights of Data Subject, including the
                  right to access and correction, and the right to object; and
                </p>
                <p className="my-4">
                  1.5 Other information that would sufficiently notify the Data
                  Subject of the nature and extent of Data Sharing and the
                  manner of processing; and
                </p>
              </div>
            </div>
            <p className="my-6">
              3. Ensure that only Personal Data that is necessary and compatible
              with the Purpose/s shall be shared to the Recipient.
            </p>
          </div>
        </div>
        <div id="section-4" className="mb-12">
          <h3 className="text-lg font-semibold mb-6">
            4. OBLIGATIONS OF THE RECIPIENT
          </h3>
          <div className="pl-6">
            <div className="my-6">
              <p>
                1. The Recipient shall have such systems in place to ensure:
              </p>
              <div className="pl-6">
                <p className="my-4">
                  1.1 Full compliance with the Data Privacy Act of 2012 and its
                  implementing rules and regulations;
                </p>
                <div className="my-4">
                  <p>
                    1.2 Administrative, physical and technical safeguards that
                    deal with the security of Personal Data and requires the
                    taking of practical steps to protect data from any loss,
                    misuse, modification, unauthorized or accidental access or
                    disclosure –
                  </p>
                  <div className="pl-6">
                    <p className="my-4">
                      1.2.1 To the nature of the Personal Data and the harm that
                      would result from such loss, misuse, modification,
                      unauthorized or accidental access or disclosure,
                      alteration or destruction;
                    </p>
                    <p className="my-4">
                      1.2.2 To the place or location where the Personal Data is
                      stored;
                    </p>
                    <p className="my-4">
                      1.2.3 to any security measures incorporated into any
                      equipment in which the Personal Data is stored;
                    </p>
                    <p className="my-4">
                      1.2.4 To the measures taken for ensuring the reliability,
                      integrity and competence of personnel having access to the
                      Personal Data; and
                    </p>
                    <p className="my-4">
                      1.2.5 To the measures taken for ensuring the secure
                      transfer of the Personal Data.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="section-5" className="mb-12">
          <h3 className="text-lg font-semibold mb-6">
            5. SECURITY BREACH MANAGEMENT
          </h3>
          <div className="pl-6">
            <p className="my-6">
              1. In the event that it/he/shebecomes aware of any suspected or
              actual breach of Personal Data by its personnel, or on/in its
              premises, facilities, system, or equipment, the Recipient shall
              promptly: (a) notify the Discloser of the Personal Data breach;
              (b) investigate the Personal Data Breach and provide the Discloser
              with information about the Personal Data breach; and (c) take
              reasonable steps to mitigate the effects and to minimize any
              damage resulting from the Personal Data breach.
            </p>
            <p className="my-6">
              2. The Parties shall cooperate with each other in the
              investigation of any breach of Personal Data, including any
              litigation against third parties which may be deemed necessary to
              protect Personal Data.
            </p>
            <div className="my-6">
              <p>
                3. The Recipientshall, within twenty-four (24) hours from
                knowledge or discovery of any suspected or actual breach of
                Personal Data, send a written notification to the Data
                Protection Officer designated by the Discloser. The written
                notification shall include:
              </p>
              <div className="pl-6">
                <div className="my-6">
                  <p>3.1 Nature of the Security Breach</p>
                  <div className="pl-6">
                    <p className="my-4">
                      3.1.1 Description of how the Security Breach occurred and
                      the vulnerability of the data processing system that
                      allowed the Security Breach;
                    </p>
                    <p className="my-4">3.1.2 Cause of the Security Breach;</p>
                    <p className="my-4">
                      3.1.3 Chronology of the events leading up to the Security
                      Breach;
                    </p>
                    <p className="my-4">
                      3.1.4 Approximate number of Data Subjects or records
                      involved; and
                    </p>
                    <p className="my-4">
                      3.1.5 Description of the likely consequences of the
                      Security Breach.
                    </p>
                  </div>
                </div>
                <div className="my-6">
                  <p>3.2 Personal Data Possibly Involved</p>
                  <div className="pl-6">
                    <p className="my-4">
                      3.2.1 description of Personal Data involved; and
                    </p>
                    <p className="my-4">
                      3.2.2 description of other information involved that may
                      be used to enable identity fraud.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="my-6">
              4. The Recipient shall, upon discovery of the Security Breach by
              its personnel, and/or on/in its premises, facilities, system, or
              equipment, shall immediately remedy the Security Breach, in
              accordance with the DPA and its IRR.
            </p>
            <p className="my-6">
              The Recipient shall, upon discovery of the Security Breach by its
              personnel, and/or on/in its premises, facilities, system, or
              equipment shall not inform any third party of the Security Breach
              affecting without first notifying the Discloser. The Parties shall
              work together in drafting the contents of any such notice prior to
              submission to the relevant authorities.
            </p>
            <div className="mb-6">
              <p>
                5. The Recipient shall, upon discovery of the Security Breach by
                its personnel, and/or on/in its premises, facilities, system, or
                equipment shall, within reasonable time, update the Discloser on
                the measures taken to address the Security Breach, with the
                following details:
              </p>
              <div className="pl-6">
                <p className="my-4">
                  5.1 Description of the measures taken or proposed to be taken
                  to address, resolve, mitigate, contain, manage or limit the
                  consequences of the Security Breach;
                </p>
                <p className="my-4">
                  5.2 Actions being taken to secure or recover the Personal Data
                  that were affected; and
                </p>
                <p className="my-4">
                  5.3 Measures being taken to prevent a recurrence of the
                  security breach; and
                </p>
                <p className="my-4">
                  5.4 Such other additional information required by the
                  Discloser.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="mt-4 flex justify-center border-t border-t-primary-100 pt-4 mx-8">
          <button
            type="button"
            onClick={handleOnAgree(0)}
            className="disabled:opacity-75 disabled:cursor-wait focus:outline-none text-primary-500 font-medium py-2 px-10 mr-2 hover:bg-primary-100 rounded-full"
          >
            DISAGREE
          </button>
          <button
            type="button"
            onClick={handleOnAgree(1)}
            className="disabled:opacity-75 disabled:cursor-wait focus:outline-none bg-primary-500 hover:bg-primary-600 hover:text-white text-secondary-500 font-semibold py-2 px-8 rounded-full tracking-wide"
          >
            AGREE
          </button>
        </div>
      </div>
    </>
  );
}
TOCModal.defaultProps = {
  onClose: () => {},
};

TOCModal.propTypes = {
  onClose: PropTypes.instanceOf(Function),
  onAgree: PropTypes.instanceOf(Function).isRequired,
};

export default TOCModal;

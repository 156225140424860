import React from "react";
import lgLogo from "assets/images/logo-with-title.svg";
import PropTypes from "prop-types";

function TitleContainer({ reference_number, subsciber_logo }) {
  return (
    <div className="flex justify-between">
      <div className="text-primary-500 flex-grow">
        <p className="font-medium">Delivery Number</p>
        <h1 className="text-4xl font-black tracking-wide uppercase">
          {reference_number}
        </h1>
      </div>
      <div className="w-auto flex items-center justify-center gap-4">
        {subsciber_logo && (
          <div className="w-16 h-16">
            <img
              src={`${subsciber_logo}-/preview/-/smart_resize/200x200/-/format/webp/`}
              alt=""
              className="object-fill object-center flex justify-center"
            />
          </div>
        )}
        <div>
          <img
            src={lgLogo}
            alt=""
            className="object-fill object-center w-24 h-auto flex justify-center"
          />
        </div>
      </div>
    </div>
  );
}
TitleContainer.defaultProps = {
  reference_number: "--",
  subsciber_logo: false,
};

TitleContainer.propTypes = {
  reference_number: PropTypes.string,
  subsciber_logo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default TitleContainer;

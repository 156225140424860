import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const FormInputCheckBox = React.forwardRef((props, ref) => {
  const {
    name,
    label,
    containerClassName,
    labelClassName,
    error,
    ...rest
  } = props;

  return (
    <div className="mx-auto inline-block">
      <div
        className={`form-check-group flex items-center ${
          containerClassName ? ` ${containerClassName}` : ""
        }`}
      >
        <input
          id={_.get(rest, "id") || name}
          name={name}
          type="checkbox"
          className="mr-1"
          ref={ref}
          {...rest}
        />
        <span />
        {label && (
          <label
            htmlFor={_.get(rest, "id") || name}
            className={`form-label-check cursor-pointer ${
              labelClassName ? ` ${labelClassName}` : ""
            }`}
          >
            {label}
          </label>
        )}
      </div>
      {error && (
        <small className="inline-block -mb-4 text-xxs text-red-500 bg-red-50 px-1 rounded">
          <i className="fa fa-exclamation-circle" /> {error?.message ?? ""}
        </small>
      )}
    </div>
  );
});

FormInputCheckBox.defaultProps = {
  label: "Activate",
  containerClassName: false,
  labelClassName: false,
  error: false,
};

FormInputCheckBox.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
};

export default React.memo(FormInputCheckBox);

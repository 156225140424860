import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FloatingFormInput from "modules/common/input/FloatingInput";
import FloatingInputMask from "modules/common/input/FloatingInputMask";
import FloatingInputSelect from "modules/common/input/FloatingInputSelect";
import FloatingTextArea from "modules/common/input/FloatingTextArea";
import AddressPicker from "modules/common/components/AddressPicker";
import { isNil, omit } from "lodash";
import FormInputCheckBox from "modules/common/input/FormInputCheckBox";
import { useHistory, useParams } from "react-router-dom";
import { closeModal, showModal } from "modules/common/modal";
import {
  objectToUpperCase,
  removeCharacterToString,
} from "modules/common/helper";
import { useApiGet, useApiLoading } from "react-reqq";
// import { toastError } from "modules/common/toast";
import PropTypes from "prop-types";
import TOCModal from "../modals/TOCModal";
import { renderDisclaimer } from "../components/RenderDisclaimer";
import * as actions from "../actions";
import * as c from "../constants";
import MapForm from "./MapForm";
import DisclaimerModal from "../modals/DisclaimerModal";

const buildingOption = [
  { label: "RESIDENTIAL", value: "residential" },
  { label: "OFFICE", value: "office" },
];

const INIT_FORM = {
  receiver_first_name: "",
  receiver_last_name: "",
  receiver_mobile_number: "",
  receiver_email: "",
  receiver_mobile_number_optional: "",
  receiver_building_type: "",
  receiver_remarks: "",
  toc: 0,
  // for address picker data
  street: "",
  zip_code: "",
  full_address: "",
  province: "",
  municipality: "",
  barangay: "",
};

function DetailsForm({ with_map, receiver_detail }) {
  const { reference_number } = useParams();
  const footerDisclaimer = useApiGet(
    `${c.GET_SUBSCRIBER_DISCLAIMERS}.footer`,
    []
  );
  const history = useHistory();
  const isLoading = useApiLoading(c.GENERATE_PAYMENT_REFERENCE, "put");
  const notValid = reference_number === "" || isNil(reference_number);
  // came from mapform
  const lnglat = useApiGet("PIN_GEOLOCATION", {
    receiver_latitude: "",
    receiver_longitude: "",
  });

  const { control, handleSubmit, errors, register, setValue } = useForm({
    defaultValues: {
      ...INIT_FORM,
      receiver_first_name: receiver_detail?.first_name ?? "",
      receiver_middle_name: receiver_detail?.middle_name ?? "",
      receiver_last_name: receiver_detail?.last_name ?? "",
      receiver_mobile_number: receiver_detail?.mobile_number ?? "",
      receiver_email: receiver_detail?.email ?? "",
    },
    resolver: yupResolver(c.schema),
  });

  const handleOnSubmit = (form) => {
    const args = {
      ...omit(form, [
        "street",
        "zip_code",
        "full_address",
        "province",
        "municipality",
        "barangay",
        "receiver_building_type",
      ]),
      receiver_building_type: form?.receiver_building_type?.value ?? "",
      receiver_line1_address: form?.full_address ?? "",
      receiver_street: form?.street ?? "",
      receiver_zip_code: form?.zip_code ?? "",
      receiver_state: form?.province?.label ?? "",
      receiver_province_id: form?.province?.data?.id ?? "",
      receiver_municipality_id: form?.municipality?.data?.id ?? "",
      receiver_barangay_code: form?.barangay?.value ?? "",
      receiver_barangay_id: form?.barangay?.data?.id ?? "",
      receiver_mobile_number: removeCharacterToString(
        form?.receiver_mobile_number,
        "-",
        ""
      ),
      receiver_mobile_number_optional: removeCharacterToString(
        form?.receiver_mobile_number_optional,
        "-",
        ""
      ),
      ...lnglat,
    };
    // TEMPORARY DISABLE
    // if (
    //   with_map &&
    //   args?.receiver_latitude === "" &&
    //   args?.receiver_longitude === ""
    // ) {
    //   toastError("Please pin your exact preferred location.", {
    //     timeout: 8000,
    //   });
    //   return;
    // }

    actions.generatePaymentReference(
      reference_number,
      {
        ...objectToUpperCase(args),
        receiver_building_type: args?.receiver_building_type,
      },
      (url) => {
        window.location.replace(url);
      }
    );
  };

  const handleOnonAgree = React.useCallback(
    (value) => {
      if (!value) {
        history.push("/cancel-transaction");
        return;
      }

      setValue("toc", value, {
        shouldValidate: true,
      });
    },
    [history, setValue]
  );

  const handleOpenTOC = React.useCallback(
    (e) => {
      if (e) e.preventDefault();

      showModal({
        title: false,
        isFocusTrap: false,
        modalSize: "modal-xl",
        content: () => <TOCModal onAgree={handleOnonAgree} />,
      });
    },
    [handleOnonAgree]
  );

  const onChangeAddress = (value) => {
    setValue("province", value?.province, {
      shouldValidate: !!value?.province,
    });
    setValue("municipality", value?.municipality, {
      shouldValidate: !!value?.municipality,
    });
    setValue("barangay", value?.barangay, {
      shouldValidate: !!value?.barangay,
    });
    setValue("zip_code", value?.zip_code, {
      shouldValidate: !!value?.zip_code,
    });
    setValue("street", value?.street, {
      shouldValidate: !!value?.street,
    });
    setValue("full_address", value?.full_address, {
      shouldValidate: !!value?.full_address,
    });
  };

  React.useEffect(() => {
    register({ name: "province" });
    register({ name: "municipality" });
    register({ name: "barangay" });
    register({ name: "street" });
    register({ name: "zip_code" });
    register({ name: "full_address" });
  }, [register]);

  // const vw = Math.max(
  //   document.documentElement.clientWidth || 0,
  //   window.innerWidth || 0
  // );

  const renderMap = React.useMemo(() => {
    // if (vw > 768) {
    return <MapForm />;
    // }
    // return null;
  }, []);

  React.useEffect(() => {
    if (reference_number) {
      const handleAgreeDisAgree = (value) => {
        if (!value) {
          closeModal();
          history.push("/cancel-transaction");
          return;
        }

        setValue("toc", value, {
          shouldValidate: true,
        });

        showModal({
          title: false,
          isFocusTrap: false,
          noClose: true,
          content: (onClose) => <DisclaimerModal onClose={onClose} />,
        });
      };

      showModal({
        title: false,
        isFocusTrap: false,
        modalSize: "modal-xl",
        content: () => <TOCModal onAgree={handleAgreeDisAgree} />,
      });
    }
  }, [handleOnonAgree, history, reference_number, setValue]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="grid lg:grid-cols-1 md:grid-cols-1 gap-10">
        <div className="space-y-3">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <Controller
              control={control}
              as={FloatingFormInput}
              label="Receiver First Name"
              name="receiver_first_name"
              id="receiver_first_name"
              required
              error={errors.receiver_first_name}
            />
            <Controller
              control={control}
              as={FloatingFormInput}
              label="Receiver Last Name"
              name="receiver_last_name"
              id="receiver_last_name"
              required
              error={errors.receiver_last_name}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <Controller
              control={control}
              as={FloatingInputMask}
              label="Receiver Mobile Number 1"
              name="receiver_mobile_number"
              id="receiver_mobile_number"
              required
              mask="+639-9999-99999"
              error={errors.receiver_mobile_number}
            />
            <Controller
              control={control}
              as={FloatingInputMask}
              label="Receiver Mobile Number 2 (Optional)"
              name="receiver_mobile_number_optional"
              id="receiver_mobile_number_optional"
              mask="+639-9999-99999"
              error={errors.receiver_mobile_number_optional}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <Controller
              as={FloatingInputSelect}
              options={buildingOption}
              label="Receiver Building Type"
              name="receiver_building_type"
              id="receiver_building_type"
              error={errors.receiver_building_type}
              required
              control={control}
            />
            <Controller
              control={control}
              as={FloatingFormInput}
              type="email"
              label="Receiver Email (Optional)"
              name="receiver_email"
              id="receiver_email"
              required
              error={errors.receiver_email}
            />
          </div>
          <AddressPicker onChange={onChangeAddress} required errors={errors} />
          <div className="col-span-1">
            <Controller
              control={control}
              as={FloatingTextArea}
              label="Remarks (Optional)"
              name="receiver_remarks"
              id="receiver_remarks"
              error={errors.receiver_remarks}
            />
          </div>
        </div>
        <div className="space-y-3">
          {with_map ? (
            <div className="lg:col-span-3 2xl:col-span-1 mt-15">
              {renderMap}
            </div>
          ) : null}
        </div>
      </div>
      <div className="my-2">
        <FormInputCheckBox
          label={
            <div className="font-base text-xs md:text-sm tracking-wide">
              <span className="ml-4 mr-1">I agree to</span>
              <button
                type="button"
                onClick={handleOpenTOC}
                className="focus:outline-none hover:underline text-secondary-700 font-semibold"
              >
                <span>Terms &amp; Conditions and Privacy Policy</span>
              </button>
            </div>
          }
          name="toc"
          ref={register}
          error={errors.toc}
        />
      </div>
      {renderDisclaimer.footer(footerDisclaimer)}
      <div className="flex justify-center mt-8">
        <button
          type="submit"
          disabled={isLoading || notValid}
          className="disabled:opacity-75 disabled:cursor-wait focus:outline-none bg-primary-500 hover:bg-primary-600 text-secondary-500 font-semibold py-3 px-8 rounded-full tracking-wide"
        >
          PROCEED
        </button>
      </div>
    </form>
  );
}
DetailsForm.defaultProps = {
  with_map: false,
  receiver_detail: {},
};

DetailsForm.propTypes = {
  with_map: PropTypes.bool,
  receiver_detail: PropTypes.instanceOf(Object),
};

export default DetailsForm;

import _ from "lodash";
import moment from "moment";
import * as React from "react";

export const isNumber = (number) => {
  if (_.isEmpty(`${number}`)) return true;
  // const regexp = /^[0-9]+([,.][0-9]+)?$/g;
  const regexp = /^[0-9.]*$/;
  return regexp.test(number);
};

export const objectToUpperCase = (params) => {
  const newObjt = {};
  Object.keys(params).map((item) => {
    const x = "";
    newObjt[item] =
      params[item] && typeof params[item] === "string"
        ? `${params[item].toUpperCase()}`
        : params[item];
    return x;
  });
  return newObjt;
};

export const stringToUpperCase = (string) => {
  if (!_.isNil(string) && typeof string === "string") {
    return string.toUpperCase();
  }
  return "";
};

export const jsUcFirst = (string) =>
  string
    .toLowerCase()
    .replace(new RegExp("(?:\\b|_)([a-z])", "g"), (e) => e.toUpperCase());

export const jsUcOnlyFirst = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getFirstMessage = (data) => {
  if (!data) return "";
  let firstMessage = "";
  let x = 0;

  Object.keys(data).map((i) => {
    if (x === 0) {
      firstMessage =
        _.get(data[i], "details") ||
        _.get(data[i], "detail") ||
        _.get(data[i], "message");
    }
    x += 1;
    return x;
  });
  return jsUcOnlyFirst(firstMessage);
};

export const removeEmpty = (obj) => _.omitBy(obj, (x) => x === "");

export const removeNull = (obj) =>
  _.omitBy(obj, (x) => typeof x === "undefined" || x === null);

export const formatedSelectOption = (
  list = [],
  value = "id",
  label = "name",
  isToUpperCase = true
) => {
  const newData = [];
  list.forEach((item) => {
    newData.push({
      value: _.get(item, `${value}`),
      label: isToUpperCase
        ? _.get(item, `${label}`).toUpperCase()
        : _.get(item, `${label}`),
      data: { ...item },
      id: _.get(item, `${value}`),
    });
  });
  return newData;
};

export const isDesktop = () =>
  !/iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const numberWithCommas = (x = 0) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const scrollTo = (id) => {
  try {
    const elem = document.getElementById(id);
    if (id) elem.scrollIntoView({ behavior: "smooth" });
  } catch (err) {
    // do nothing...
  }
};

export const hideMobileNumber = (mobileNumber) => {
  if (_.isEmpty(mobileNumber)) {
    return "";
  }

  return mobileNumber.replace(/(\d{1})\d{7}/, "$1*******");
};

export const formatDate = (date, format = "MM/DD/YYYY") => {
  if (!date) return "-";
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return "-";
  return moment(d).format(format);
};

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ""));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== "boolean" ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const loadIncludedToList = (items, included) =>
  items.map((x) => {
    const rowIncluded = {};
    _.forOwn(x.relationships, (v, k) => {
      rowIncluded[k] = Array.isArray(v.data)
        ? v.data.map(
            (z) =>
              included.find(
                (y) => y.type === _.get(z, "type") && y.id === _.get(z, "id")
              ) || {}
          )
        : included.find(
            (y) =>
              y.type === _.get(v, "data.type") && y.id === _.get(v, "data.id")
          ) || {};
    });
    return { ...x, included: rowIncluded };
  });

export const flatIncludedToItem = (x, included) => {
  const rowIncluded = {};
  _.forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === _.get(z, "type") && y.id === _.get(z, "id")
            ) || {}
        )
      : included.find(
          (y) =>
            y.type === _.get(v, "data.type") && y.id === _.get(v, "data.id")
        ) || {};
  });
  return { ...x, included: rowIncluded };
};

export const removeObjectToList = (list = [], id = "", withId = false) => {
  let newList = [];
  if (withId) {
    newList = list.filter((item) => `${item.id}` !== `${id}`);
    return newList;
  }
  newList = list.filter((item) => `${item}` !== `${id}`);
  return newList;
};

export const insertUpdateObjectToList = (list = [], data = {}) => {
  const newList = list.map((item) => {
    if (`${data.id}` !== `${item.id}`) return item;
    return _.assign({}, item, data);
  });

  return newList;
};

export const createRecursiveArr = (items, id = null, link = "parent_id") =>
  items
    .filter((item) => `${_.get(item, `${link}`)}` === `${id}`)
    .map((item) => ({
      ...item,
      children: createRecursiveArr(items, item.id, link),
    }));

export const makeArray = (count, content) => {
  const result = [];
  if (typeof content === "function") {
    for (let i = 0; i < count; i += 1) {
      result.push(content(i));
    }
  } else {
    for (let i = 0; i < count; i += 1) {
      result.push(content);
    }
  }
  return result;
};

export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          const x = params;
          const [key, value] = param.split("=");
          x[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
          return x;
        }, {})
    : {};
};

export const objectToQueryString = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const storage = {
  get: (key) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return false;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
  clear: () => sessionStorage.clear(),
  clearAll: () => {
    sessionStorage.clear();
    localStorage.clear();
  },
};

const cachedScripts = [];

export const useScript = (id, src) => {
  // Keeping track of script loaded and error state
  const [state, setState] = React.useState({
    loaded: false,
    error: false,
  });

  // eslint-disable-next-line consistent-return
  React.useLayoutEffect(() => {
    // Create script
    const fjs = document.getElementsByTagName("script")[0];
    const script = document.createElement("script");

    // Script event listener callbacks for load and error
    const onScriptLoad = () => {
      setState({
        loaded: true,
        error: false,
      });
    };

    const onScriptError = () => {
      // Remove from cachedScripts we can try loading again
      const i = cachedScripts.indexOf(src);
      if (i >= 0) cachedScripts.splice(i, 1);
      script.remove();

      setState({
        loaded: true,
        error: true,
      });
    };

    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false,
      });
    } else {
      cachedScripts.push(src);

      script.src = src;
      script.id = id;
      script.async = true;
      fjs.parentNode.insertBefore(script, fjs);

      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);

      // Add script to document body
      document.body.appendChild(script);
    }
    // Remove event listeners on cleanup
    return () => {
      script.removeEventListener("load", onScriptLoad);
      script.removeEventListener("error", onScriptError);
    };
  }, [src, id]);

  return [state.loaded, state.error];
};

export const removeCharacterToString = (str, target = /\s/g, replace = "") => {
  if (!str) return "";
  return str.replaceAll(target, replace);
};

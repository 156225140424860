import PropTypes from "prop-types";
import React, { useState } from "react";
import { useScript } from "modules/common/helper";
import { toastError } from "modules/common/toast";
import marker from "./marker.png";
import mapStyle from "./map-style";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const parseCenter = (geoloc) => {
  try {
    const arr = (geoloc || "").split(",");
    return {
      lat: +(arr[0] || "14.5995").trim(),
      lng: +(arr[1] || "120.9842").trim(),
    };
  } catch (err) {
    return { lat: 14.5995, lng: 120.9842 };
  }
};
const transformCenter = (location) => {
  try {
    return `${location.lat()},${location.lng()}`;
  } catch (err) {
    return null;
  }
};

let lastZoom = 13;

const Maps = ({ name, setRef, defaultCenter, onChange, height }) => {
  const mapRef = React.useRef();
  const userFirstAction = React.useRef(false);
  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    const g = google;
    const map = new g.maps.Map(mapRef.current, {
      mapTypeId: "roadmap",
      disableDefaultUI: true,
      zoom: lastZoom,
      center: parseCenter(defaultCenter),
      zoomControl: true,
      zoomControlOptions: {
        position: g.maps.ControlPosition.RIGHT_TOP,
      },
      fullscreenControl: true,
    });
    const styledMapType = new g.maps.StyledMapType(mapStyle, {
      name: "Custom",
    });
    map.mapTypes.set("styled_map", styledMapType);
    map.setMapTypeId("styled_map");

    let isDragging = false;
    g.maps.event.addListener(map, "zoom_changed", () => {
      lastZoom = map.getZoom();
    });
    g.maps.event.addListener(map, "idle", () => {
      if (!isDragging) {
        onChange(transformCenter(map.getCenter()));
      }
    });
    g.maps.event.addListener(map, "dragstart", () => {
      userFirstAction.current = true;
      isDragging = true;
    });
    g.maps.event.addListener(map, "dragend", () => {
      isDragging = false;
      g.maps.event.trigger(this, "idle", {});
    });

    if (navigator.geolocation && !defaultCenter) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (userFirstAction.current === true) return;
        const initialLocation = new g.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        map.setCenter(initialLocation);
      });
    }

    setRef(map);
    // eslint-disable-next-line
  }, [name, onChange]);
  return (
    <div className="bg-white rounded-lg mt-2 p-3 relative">
      <div className="relative w-full" style={{ height }}>
        <img
          className="absolute z-20"
          src={marker}
          alt=""
          style={{
            height: "37px",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(calc(-50% - 17px))",
          }}
        />
        <div ref={mapRef} className="absolute h-full w-full inset-0" />
      </div>
    </div>
  );
};

Maps.defaultProps = {
  onChange: false,
};

Maps.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  setRef: PropTypes.func.isRequired,
  defaultCenter: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

function GoogleAddressPicker({ disclaimer, height, onChange }) {
  const value = "";

  const inputRef = React.useRef();
  const mapRef = React.useRef();

  const [isLoaded] = useScript(
    "google-api-script",
    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&region=ph`
  );
  React.useEffect(() => {
    if (!isLoaded) return;
    try {
      // eslint-disable-next-line no-undef
      const g = google;

      const autocomplete = new g.maps.places.Autocomplete(inputRef.current, {
        // types: ['establishment'],
        fields: ["geometry"],
        componentRestrictions: { country: "ph" },
      });
      autocomplete.addListener("place_changed", () => {
        try {
          const place = autocomplete.getPlace();
          // const address = inputRef.current.value;
          if (typeof onChange === "function") {
            onChange(transformCenter(place.geometry.location));
          }
          mapRef.current.fitBounds(place.geometry.viewport);
          mapRef.current.setCenter(place.geometry.location);
        } catch (err) {
          toastError("Unable to get place!");
        }
      });
      // inputRef.current.placeholder = 'Search your street and house number.';
    } catch (err) {
      // DO NOTHING
    }
  }, [isLoaded, onChange]);

  const [id] = useState("map-picker");
  const handleSetRef = (map_ref) => {
    mapRef.current = map_ref;
  };
  return (
    <div>
      <div className="relative z-[1]">
        <div className="floating-form">
          <input
            id={id}
            type="text"
            ref={inputRef}
            autoComplete="chrome-off"
            className="floating-input pr-8"
            disabled={!isLoaded}
            defaultValue={value}
          />
          <label className="floating-label" htmlFor={id}>
            Search your street and house number.
          </label>
        </div>
        {/* <div
          className={cn(
            'relative border rounded-md bg-white px-3 py-2 focus-within:ring-1',
            'border-gray-200 focus-within:ring-primary-600 focus-within:border-primary-600',
          )}
        >
          <input
            ref={inputRef}
            id={id}
            type="text"
            name="name"
            className={cn(
              'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-base h-[17px]',
              'focus:outline-none',
              'pr-10'
            )}
            disabled={!isLoaded}
            defaultValue={value}
          />
        </div> */}
        {disclaimer && disclaimer}
        {isLoaded ? (
          <div>
            <Maps
              setRef={handleSetRef}
              defaultCenter=""
              name="geoloc"
              height={height}
              onChange={onChange}
            />
          </div>
        ) : null}
        <span className="text-xs text-primary-500 italic">
          Tip: You may drag the pin to set your exact preferred location.
        </span>
      </div>
    </div>
  );
}

GoogleAddressPicker.defaultProps = {
  height: "40vh",
  disclaimer: false,
};

GoogleAddressPicker.propTypes = {
  disclaimer: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element,
    PropTypes.string,
  ]),
  height: PropTypes.string,
  onChange: PropTypes.instanceOf(Function).isRequired,
};

export default GoogleAddressPicker;

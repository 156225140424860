import React from "react";
import background from "assets/images/full-background.svg";
import lgLogo from "assets/images/logo.svg";
import PropTypes from "prop-types";
import get from "lodash/get";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.warn(error, errorInfo); // eslint-disable-line
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <div
            className="flex justify-center items-center h-screen w-full bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${background})`,
            }}
          >
            <div className="flex flex-col justify-center">
              <img src={lgLogo} alt="" />
              <div className="gradient-underline my-10" />
              <p className="text-white text-lg text-center">
                Unable to load page.{" "}
                <a
                  href={get(window, "location.pathname")}
                  className="underline"
                >
                  Try again
                </a>
              </p>
            </div>
          </div>
        </div>
      );
    }
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default ErrorBoundary;

import React from "react";
import { useApiGet, useApiLoading } from "react-reqq";
import { isEmpty } from "lodash";
import * as c from "./constants";
import * as actions from "./actions";

export const useGetSubsciberByRefNumber = (reference_number) => {
  const [isApiLoading, setIfApiLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const isGetSubscriberApiLoading = useApiLoading(
    c.GET_SUBSCRIBER_BY_REF_NUMBER,
    "get"
  );

  const isGetSubscriberContactApiLoading = useApiLoading(
    c.GET_SUBSCRIBER_CONTACTS,
    "get"
  );

  const isGetSubscriberDiscalimerApiLoading = useApiLoading(
    c.GET_SUBSCRIBER_DISCLAIMERS,
    "get"
  );

  const data = useApiGet(c.GET_SUBSCRIBER_BY_REF_NUMBER, {});
  const disclaimers = useApiGet(c.GET_SUBSCRIBER_DISCLAIMERS, []);
  const { email, contact_number } = useApiGet(c.GET_SUBSCRIBER_CONTACTS, {});

  React.useEffect(() => {
    if (!isGetSubscriberApiLoading && data?.included?.subscriber?.id) {
      actions.getSubscriberContacts(data?.included?.subscriber?.id);
      actions.getSubscriberDisclaimers(data?.included?.subscriber?.id);
    }
  }, [data?.included?.subscriber?.id, isGetSubscriberApiLoading]);

  React.useEffect(() => {
    if (reference_number) {
      actions.getSubscriberByRefNumber(reference_number, () => {
        setError(true);
        setTimeout(() => {
          setIfApiLoading(false);
        }, 500);
      });
    }
  }, [reference_number]);

  React.useEffect(() => {
    if (
      !isGetSubscriberApiLoading &&
      !isGetSubscriberContactApiLoading &&
      !isGetSubscriberDiscalimerApiLoading &&
      !isEmpty(data)
    ) {
      setIfApiLoading(false);
    }
  }, [
    data,
    isApiLoading,
    isGetSubscriberApiLoading,
    isGetSubscriberContactApiLoading,
    isGetSubscriberDiscalimerApiLoading,
  ]);
  return [isApiLoading, error, data, disclaimers, email, contact_number];
};

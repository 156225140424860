/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { isEmpty } from "lodash";

const FloatingInputSelect = React.forwardRef((props, ref) => {
  const {
    name,
    label,
    required,
    error,
    containerClassName,
    labelClassName,
    options,
    ...rest
  } = props;
  const selectRef = React.useRef(null);

  const [labelIsOnTop, setLabelIsOnTop] = React.useState(false);
  const [valueHolder, setValueHolder] = React.useState("");

  const handleFocus = () => {
    selectRef.current.focus();
    setLabelIsOnTop(true);
  };

  const handleBlur = () => {
    if (!valueHolder) {
      setLabelIsOnTop(false);
    }
  };
  const handleChange = (e) => {
    const { value } = e;
    setValueHolder(value);
    if (!value) {
      setLabelIsOnTop(false);
    }
  };

  return (
    <div ref={ref} className="w-full relative">
      <div className={containerClassName}>
        <Select
          id={rest?.id ?? name}
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={selectRef}
          onChange={handleChange}
          classNamePrefix="react-select"
          className="react-select-container"
          options={options}
          value={rest?.value === {} ? "" : rest?.value}
          {...rest}
        />
        {label && (
          <label
            htmlFor={rest?.id ?? name}
            className={labelClassName}
            onClick={handleFocus}
            role="presentation"
            style={{
              transform: !labelIsOnTop
                ? "translate3d(0, 0, 0) scale3d(1, 1, 1)"
                : "translate3d(0, -17px, 0) scale3d(0.7, 0.7, 1)",
            }}
          >
            {label} {required && <span className="text-red-600">*</span>}
          </label>
        )}
      </div>
      {error && (
        <small className="flex text-xxs absolute -bottom-1 items-center gap-1 right-0 px-2 bg-red-50 rounded text-red-500">
          <i className="fa fa-exclamation-circle" /> {error?.message ?? ""}
        </small>
      )}
    </div>
  );
});

FloatingInputSelect.defaultProps = {
  label: "Name",
  containerClassName: "floating-form-select",
  labelClassName: "floating-form-select-label",
  required: false,
  error: false,
  options: [],
  id: false,
};

FloatingInputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  options: PropTypes.instanceOf(Array),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default FloatingInputSelect;

import React from "react";
// import GoogleAddressPicker from "modules/common/components/google-address-picker"; // LEGACY PICKER
import GoogleAddressPicker from "modules/common/components/GoogleAddressPicker";
import PropTypes from "prop-types";
import { req, useApiGet } from "react-reqq";
import { renderDisclaimer } from "../components/RenderDisclaimer";
import * as c from "../constants";

function MapForm({ height }) {
  const { email, contact_number } = useApiGet(c.GET_SUBSCRIBER_CONTACTS, {});

  const handleOnChange = (value) => {
    const [receiver_latitude, receiver_longitude] = (value || "").split(",");
    const lnglat = {
      receiver_latitude,
      receiver_longitude,
    };
    req.set("PIN_GEOLOCATION", lnglat);

    /* LEGACY PICKER
    const geoloc = JSON.parse(JSON.stringify(value));

    const lnglat = {
      receiver_latitude: `${geoloc?.geometry?.location?.lat}`,
      receiver_longitude: `${geoloc?.geometry?.location?.lng}`,
    };
    req.set("PIN_GEOLOCATION", lnglat);
    */
  };

  return (
    <div className="mt-4 lg:mt-0 lg:pr-8">
      <h1 className="text-3xl font-bold text-primary-500 mb-3">
        Pin your preferred delivery location?
      </h1>
      <GoogleAddressPicker
        height={height}
        onChange={handleOnChange}
        disclaimer={renderDisclaimer.map}
      />
      <div className="mt-2">
        <div className="text-gray-600 text-sm space-y-4">
          {renderDisclaimer.hotlines(contact_number)}
          {renderDisclaimer.email(email)}
        </div>
      </div>
    </div>
  );
}
MapForm.defaultProps = {
  height: "80vh",
};

MapForm.propTypes = {
  height: PropTypes.string,
};

export default MapForm;

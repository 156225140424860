import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { storage } from "modules/common/helper";
import CancelTransaction from "./containers/CancelTransaction";
import FormContainer from "./containers/FormContainer";
import Page404Container from "./containers/Page404Container";

function PartialContainer() {
  const match = useRouteMatch();

  React.useEffect(() => {
    return () => {
      storage.clearAll();
    };
  }, []);

  return (
    <div className="w-full h-full lg:py-12">
      <div className="xl:w-10/12 m-auto">
        <div className="bg-white rounded shadow-lg">
          <Switch>
            <Route
              path={`${match.path}error-page`}
              component={Page404Container}
            />
            <Route
              path={`${match.path}cancel-transaction`}
              component={CancelTransaction}
            />
            <Route
              path={`${match.path}:reference_number?`}
              component={FormContainer}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}
PartialContainer.defaultProps = {};

PartialContainer.propTypes = {};

export default PartialContainer;

import { req } from "react-reqq";
import _ from "lodash";

const formatedSelectOption = (
  list = [],
  value = "id",
  label = "name",
  isToUpperCase = true
) => {
  const newData = [];
  list.forEach((item) => {
    newData.push({
      value: _.get(item, `${value}`),
      // eslint-disable-next-line no-nested-ternary
      label: isToUpperCase
        ? _.get(item, `${label}`)
          ? _.get(item, `${label}`).toUpperCase()
          : ""
        : _.get(item, `${label}`),
      data: { ...item },
      id: _.get(item, `${value}`),
    });
  });
  return newData;
};

export const GET_PROVINCE_LIST = (subscriber_id, params, callback) =>
  req.get({
    key: "GET_PROVINCE_LIST",
    url: `/api/v1/common/subscribers/${subscriber_id}/provinces`,
    params,
    onSuccess: (res) => {
      const data = _.get(res, "response.data", []);
      callback(
        formatedSelectOption(data, "attributes.code", "attributes.name")
      );
    },
  });

export const GET_MUNICIPALITY_LIST = (
  subscriber_id,
  province_id,
  params,
  callback
) =>
  req.get({
    key: "GET_MUNICIPALITY_LIST",
    url: `/api/v1/common/subscribers/${subscriber_id}/provinces/${province_id}/municipalities`,
    params,
    onSuccess: (res) => {
      const data = _.get(res, "response.data", []);
      callback(
        formatedSelectOption(data, "attributes.code", "attributes.name")
      );
    },
  });

export const GET_BARANGAY_LIST = (
  subscriber_id,
  province_id,
  municipality_id,
  params,
  callback
) =>
  req.get({
    key: "GET_BARANGAY_LIST",
    url: `/api/v1/common/subscribers/${subscriber_id}/provinces/${province_id}/municipalities/${municipality_id}/barangays`,
    params,
    onSuccess: (res) => {
      const data = _.get(res, "response.data", []);
      callback(
        formatedSelectOption(data, "attributes.code", "attributes.name")
      );
    },
  });

import React from "react";
import PropTypes from "prop-types";
import { useApiGet } from "react-reqq";
import { renderDisclaimer } from "../components/RenderDisclaimer";
import * as c from "../constants";

function DisclaimerModal({ disclaimer_type, handleIfInsideNCR, onClose }) {
  const { email, contact_number } = useApiGet(c.GET_SUBSCRIBER_CONTACTS, {});
  const { rate } = useApiGet(c.GET_SUBSCRIBER_DISCLAIMERS, {});

  if (disclaimer_type === 1) {
    return (
      <div>
        <div className="space-y-4">
          {renderDisclaimer.rate(rate)}
          {renderDisclaimer.limited_area}
          <div className="text-gray-600 text-sm space-y-4">
            {renderDisclaimer.hotlines(contact_number)}
            {renderDisclaimer.email(email)}
          </div>
        </div>
        <div className="mt-4 flex justify-center pt-4 mx-8 gap-3">
          <button
            type="button"
            onClick={onClose}
            className="disabled:opacity-75 disabled:cursor-wait focus:outline-none bg-primary-500 hover:bg-primary-600 hover:text-white text-white font-semibold py-2 px-8 rounded-full tracking-wide"
          >
            PROCEED
          </button>
        </div>
      </div>
    );
  }
  return (
    <div>
      {renderDisclaimer.ncr_modal}
      <div className="text-gray-600 text-sm space-y-4">
        {renderDisclaimer.hotlines(contact_number)}
        {renderDisclaimer.email(email)}
      </div>
      <div className="mt-4 flex justify-center pt-4 mx-8 gap-3">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleIfInsideNCR(false);
          }}
          className="disabled:opacity-75 disabled:cursor-wait focus:outline-none bg-red-500 hover:bg-red-600 hover:text-white text-white font-semibold py-2 px-8 rounded-full tracking-wide"
        >
          I&apos;M OUTSIDE NCR
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleIfInsideNCR(true);
          }}
          className="disabled:opacity-75 disabled:cursor-wait focus:outline-none bg-primary-500 hover:bg-primary-600 hover:text-white text-white font-semibold py-2 px-8 rounded-full tracking-wide"
        >
          I&apos;M INSIDE NCR
        </button>
      </div>
    </div>
  );
}
DisclaimerModal.defaultProps = {
  disclaimer_type: 1,
  handleIfInsideNCR: () => {},
  onClose: () => {},
};

DisclaimerModal.propTypes = {
  disclaimer_type: PropTypes.number,
  handleIfInsideNCR: PropTypes.instanceOf(Function),
  onClose: PropTypes.instanceOf(Function),
};

export default DisclaimerModal;

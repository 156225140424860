import * as yup from "yup";

export const GET_SUBSCRIBER_BY_REF_NUMBER = "GET_SUBSCRIBER_BY_REF_NUMBER";
export const GENERATE_PAYMENT_REFERENCE = "GENERATE_PAYMENT_REFERENCE";
export const GET_SUBSCRIBER_CONTACTS = "GET_SUBSCRIBER_CONTACTS";
export const GET_SUBSCRIBER_DISCLAIMERS = "GET_SUBSCRIBER_DISCLAIMERS";

export const schema = yup.object({
  receiver_first_name: yup.string().required("First name is required."),
  receiver_last_name: yup.string().required("Last name is required."),
  receiver_mobile_number: yup.string().required("Mobile number is required."),
  receiver_building_type: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required("Building type is required."),
  toc: yup.boolean().oneOf([true], "You must accept the terms and conditions"),

  street: yup.string().required("Street name is required."),
  zip_code: yup.string().required("Zipcode name is required."),
  full_address: yup.string().required("Full address details is required."),
  province: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable()
    .required("Province field is required."),
  municipality: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable()
    .required("Municipality field is required."),
  barangay: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable()
    .required("Barangay field is required."),
});
